import React from "react"
import {
  EmptyText,
  NoItemsContainer,
  RightContainer,
} from "../utils/ProfiloStyles"
import { useSelector } from "react-redux"
import styled from "styled-components"
import { selectUserPref } from "../../../redux/userSlice"
import Preferences from "../../Preferences/Preferences"
import navigateToTrattamento from "../../../functions/navigateToTrattamento"

const Text = styled.p`
  color: ${props => props.theme.black};
  margin-bottom: 30px;
  font-size: 14px;
  & > a {
    text-decoration: none;
  }
`

const PrefContainer = styled.div`
  margin-right: 5%;
`

export default function ProfiloPreferenzeContainer() {
  const userPrefs = useSelector(selectUserPref)

  if (!userPrefs?.length > 0) {
    return (
      <RightContainer style={{ display: "flex", flexDirection: "column" }}>
        <NoItemsContainer />
        <EmptyText style={{ marginTop: 15 }}>
          Non hai espresso nessuna preferenza
        </EmptyText>
      </RightContainer>
    )
  }

  return (
    <RightContainer>
      <PrefContainer>
        <Text>
          Aggiungi preferenze di{" "}
          <a href={"/medicina-estetica"}>medicina estetica</a> e{" "}
          <a href={"/chirurgia-estetica"}>chirurgia estetica</a>{" "}
        </Text>
        {userPrefs.map(pref => (
          <Preferences.PrefInnerItem key={pref.id_sys}>
            <Preferences.PrefInnerItemText
              onClick={() =>
                navigateToTrattamento({ ...pref, id_sys: pref.id_tra })
              }
            >
              {pref.trattamento}
            </Preferences.PrefInnerItemText>
            <Preferences.PrefInnerItemHeart
              pref={{ ...pref, id_sys: pref.id_tra }}
            />
          </Preferences.PrefInnerItem>
        ))}
      </PrefContainer>
    </RightContainer>
  )
}
