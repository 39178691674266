import AppBar from "@material-ui/core/AppBar"
import Toolbar from "@material-ui/core/Toolbar"
import Typography from "@material-ui/core/Typography"
import IconButton from "@material-ui/core/IconButton"
import ArrowLeft from "@material-ui/icons/ArrowLeft"
import React from "react"
import { makeStyles } from "@material-ui/core/styles"
import { colors } from "../../constants/Colors"

const useStyles = makeStyles(theme => ({
  appBar: {
    position: "relative",
    zIndex: 0,
    background: colors.BLUE,
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
}))

export default function ReusableAppBar({ title = "Profilo", onBackPress }) {
  const classes = useStyles()
  return (
    <AppBar className={classes.appBar}>
      <Toolbar>
        {onBackPress && (
          <IconButton
            edge="start"
            color="inherit"
            onClick={onBackPress}
            aria-label="close"
          >
            <ArrowLeft />
          </IconButton>
        )}
        <Typography variant="h6" className={classes.title}>
          {title}
        </Typography>
      </Toolbar>
    </AppBar>
  )
}
