import React, { useEffect, useRef, useState } from "react"
import {
  fetchNotificheAsync,
  fetchRichiesteAsync,
  selectCurrentUser,
  selectUserNotifiche,
  selectUserRichieste,
} from "../../../redux/userSlice"
import { useDispatch, useSelector } from "react-redux"
import styled from "styled-components"
import {
  EmptyText,
  Medium,
  NoItemsContainer,
  RightContainer,
} from "../utils/ProfiloStyles"
import { colors } from "../../../constants/Colors"
import MockedNotifiche from "../../../constants/MockedNotifiche"
import BellIcon from "@material-ui/icons/Notifications"
import IconButton from "@material-ui/core/IconButton"
import navigateToChirurgo from "../../../functions/navigateToChirurgo"
import { breakpoints } from "../../../constants/breakpoints"
import ReusableModal from "../../ReusableModal/ReusableModal"
import ProfiloRichiesta from "../ProfiloRichiesteContainer/ProfiloRichiesta"
import ReusableDialog from "../../ReUsableDialog/ReusableDialog"
import useWindowDimensions from "../../../hooks/useWindowDimensions"

const Notifica = styled.div``
const Row = styled.div`
  display: flex;
  align-items: flex-start;
  align-content: center;
  flex-direction: row;
`

export default function ProfiloNotificheContainer() {
  const notifiche = useSelector(selectUserNotifiche)
  const richieste = useSelector(selectUserRichieste)
  const dispatch = useDispatch()
  const user = useSelector(selectCurrentUser)
  const [open, setOpen] = useState(false)
  const [richiesta, setRichiesta] = useState(null)
  const parentRef = useRef()
  const { width } = useWindowDimensions()
  //const notifiche = MockedNotifiche

  useEffect(() => {
    dispatch(fetchNotificheAsync(user?.user_id))
    dispatch(fetchRichiesteAsync(user?.user_id))
  }, [])

  if (!notifiche?.length > 0) {
    return (
      <RightContainer style={{ display: "flex", flexDirection: "column" }}>
        <NoItemsContainer />
        <EmptyText style={{ marginTop: 15 }}>
          Non hai ricevuto nessuna notifica
        </EmptyText>
      </RightContainer>
    )
  }

  const onNotificationClick = n => {
    if (n.tipo_notifica === "Promozione") {
      navigateToChirurgo({ id_sys: n.id_chirurgo, nome: n.chirurgo })
    } else if (n.tipo_notifica === "Preventivo") {
      setRichiesta(richieste.find(r => r.id_sys === n.id_richiesta))
      setOpen(true)
    }
  }

  return (
    <RightContainer>
      {width > breakpoints.tablet ? (
        <ReusableModal
          open={open}
          close={() => setOpen(false)}
          title={"Richiesta"}
          parentRef={parentRef}
          content={
            <ProfiloRichiesta
              chirurgoNome={richiesta?.chirurgo}
              richiesta={richiesta}
            />
          }
        />
      ) : (
        <ReusableDialog
          open={open}
          close={() => setOpen(false)}
          title={"Richiesta"}
          parentRef={parentRef}
          content={
            <ProfiloRichiesta
              chirurgoNome={richiesta?.chirurgo}
              richiesta={richiesta}
            />
          }
        />
      )}
      {notifiche.map(n => (
        <Row>
          <IconButton
            onClick={() => onNotificationClick(n)}
            style={{ width: 30, height: 30 }}
          >
            <BellIcon style={{ fontSize: 16, color: colors.DARK_BLUE }} />
          </IconButton>
          <Notifica style={styles.container} key={n.id_sys}>
            <Medium style={styles.title}>{n.titolo}</Medium>
            <br />
            <Medium style={styles.description}>{n.testo}</Medium>
          </Notifica>
        </Row>
      ))}
    </RightContainer>
  )
}

const styles = {
  container: {
    borderBottomColor: colors.LIGHT_GRAY_01,
    borderBottomWidth: 1,
    marginBottom: 20,
  },
  title: {
    fontSize: 15,
  },
  description: {
    marginTop: 10,
    fontSize: 14,
    color: colors.GRAY_01,
    marginBottom: 10,
  },
}
