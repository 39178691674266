import React, { useState } from "react"
import styled from "styled-components"
import { RightContainer } from "../utils/ProfiloStyles"
import { useDispatch, useSelector } from "react-redux"
import { useForm } from "react-hook-form"
import { MobileFormInputStyle } from "../../MobileFormInputs/MobileFormInput"
import MobileFormAutoComplete from "../../MobileFormInputs/MobileFormAutoComplete"
import MobileFormSelect from "../../MobileFormSelect/MobileFormSelect"
import MobileFormInputMultiLine from "../../MobileFormInputs/MobileFormInputMultiLine"
import CustomButton from "../../Atoms/Button"
import { selectCurrentUser, updateUserAsync } from "../../../redux/userSlice"
import { breakpoints, device } from "../../../constants/breakpoints"

const Selections = ["Uomo", "Donna"]

const Label = styled.label`
  margin-top: 15px;
  margin-left: 2px;
  margin-bottom: 10px;
  font-size: 14px;
`

export default function ProfiloDatiUtenteContainer() {
  const { register, handleSubmit } = useForm()
  const user = useSelector(selectCurrentUser)
  const dispatch = useDispatch()
  const [selected, setSelected] = useState(user.user_sesso === "M" ? 0 : 1)
  const onSubmit = data => {
    dispatch(
      updateUserAsync({ ...data, sesso: selected === 0 ? "M" : "F" }, user)
    )
  }

  return (
    <RightContainer>
      <div style={{ maxWidth: breakpoints.tabletSm, margin: "auto" }}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Label htmlFor="nome" style={{ marginBottom: 10 }}>
            Nome e Cognome
          </Label>
          <MobileFormInputStyle
            id="nome"
            defaultValue={user.user_name}
            type="text"
            required
            {...register("nome")}
          />
          <Label htmlFor="Nickname">Nickname</Label>
          <MobileFormInputStyle
            id="Nickname"
            defaultValue={user.user_nickname}
            type="text"
            required
            {...register("nickname")}
          />
          <Label htmlFor="city">Città</Label>
          <MobileFormInputStyle
            id="city"
            defaultValue={user.user_citta}
            {...register("comune")}
            type={"text"}
            required
          />
          <Label htmlFor="Email">Email</Label>
          <MobileFormInputStyle
            id="Email"
            {...register("email")}
            defaultValue={user.user_email}
            type={"email"}
            required
          />

          <Label style={{ marginTop: 20 }}>Sesso</Label>
          <MobileFormSelect
            setSelected={setSelected}
            selected={selected}
            items={Selections}
          />
          <div style={{ textAlign: "center", marginTop: 40 }}>
            <CustomButton>Salva</CustomButton>
          </div>
        </form>
      </div>
    </RightContainer>
  )
}
