import React from "react"
import { useSelector } from "react-redux"
import { selectUserEsperienze } from "../../../redux/userSlice"
import {
  CardsImage,
  EmptyText,
  Medium,
  NoItemsContainer,
  RightContainer,
} from "../utils/ProfiloStyles"
import { colors } from "../../../constants/Colors"
import Stars from "../../Stars/Stars"
import { navigate } from "gatsby"
import { EsperienzaLink } from "../../../functions/EsperienzaLink"

export default function ProfiloRecensioniContainer() {
  const recensioni = useSelector(selectUserEsperienze)

  if (!recensioni?.length > 0) {
    return (
      <RightContainer style={{ display: "flex", flexDirection: "column" }}>
        <NoItemsContainer />
        <EmptyText style={{ marginTop: 15 }}>
          Non hai scritto nessuna recensione
        </EmptyText>
      </RightContainer>
    )
  }
  return (
    <RightContainer>
      {recensioni.map(esperienza => (
        <div style={styles.container}>
          <img
            style={styles.image}
            src={
              esperienza.ChirurgoImage ||
              "http://chirurghiestetici.netfood.cloud/public/images/1/1_c_5ff20ef2-c2ce-446b-99e0-6cebe8d1a5f2.jpg"
            }
          />
          <div style={styles.column}>
            <Medium style={styles.chirurgo}>{esperienza.chirurgo}</Medium>
            <Medium style={styles.trattamento}>{esperienza.trattamento}</Medium>
            <Stars style={{ marginTop: 8 }} rating={esperienza.rating} />
            <div
              style={styles.button}
              onClick={() =>
                navigate(
                  EsperienzaLink({
                    trattamentoId: esperienza.id_tra,
                    esperienzaId: esperienza.id_sys,
                  })
                )
              }
            >
              <span style={{ color: colors.BLUE, fontSize: 12 }}>Leggi</span>
            </div>
          </div>
        </div>
      ))}
    </RightContainer>
  )
}

const styles = {
  container: {
    display: "flex",
    marginVertical: 10,
    borderBottomColor: colors.LIGHT_GRAY_01,
    borderBottomWidth: 1,
    flexDirection: "row",
  },
  chirurgo: {
    fontSize: 16,
    color: colors.BLACK,
  },
  trattamento: {
    marginTop: 5,
    fontSize: 13,
    color: colors.GRAY_01,
    textTransform: "uppercase",
  },
  motivazione: {
    marginTop: 5,
    color: colors.LIGHT_BLUE,
    fontSize: 13,
  },
  data: {
    marginTop: 5,
    color: colors.GRAY_01,
    fontSize: 13,
  },
  image: {
    ...CardsImage,
  },
  column: {
    display: "flex",
    flexDirection: "column",
  },
  button: {
    cursor: "pointer",
  },
}
