import React, { useState } from "react"
import styled from "styled-components"
import { RightContainer } from "../utils/ProfiloStyles"
import { useDispatch, useSelector } from "react-redux"
import { useForm } from "react-hook-form"
import { MobileFormInputStyle } from "../../MobileFormInputs/MobileFormInput"
import MobileFormAutoComplete from "../../MobileFormInputs/MobileFormAutoComplete"
import MobileFormSelect from "../../MobileFormSelect/MobileFormSelect"
import MobileFormInputMultiLine from "../../MobileFormInputs/MobileFormInputMultiLine"
import CustomButton from "../../Atoms/Button"
import {
  selectCurrentUser,
  updatePasswordAsync,
  updateUserAsync,
} from "../../../redux/userSlice"
import { breakpoints, device } from "../../../constants/breakpoints"
import { openErrorToast } from "../../../redux/toastSlice"

const Label = styled.label`
  margin-top: 15px;
  margin-left: 2px;
  margin-bottom: 10px;
  font-size: 14px;
`

export default function ProfiloCambiaPasswordContainer() {
  const { register, handleSubmit } = useForm()
  const user = useSelector(selectCurrentUser)
  const dispatch = useDispatch()

  const onSubmit = data => {
    if (data.new_password !== data.re_password) {
      dispatch(openErrorToast("Le password non corrispondono"))
    }
    dispatch(updatePasswordAsync({ ...data, id_user: user.user_id }))
  }

  return (
    <RightContainer>
      <div style={{ maxWidth: breakpoints.tabletSm, margin: "auto" }}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Label htmlFor="old_password" style={{ marginBottom: 10 }}>
            Vecchia password
          </Label>
          <MobileFormInputStyle
            id="old_password"
            defaultValue={user.user_name}
            type="password"
            required
            {...register("old_password")}
          />
          <Label htmlFor="new_password">Nuova Password</Label>
          <MobileFormInputStyle
            id="new_password"
            defaultValue={user.user_nickname}
            type="password"
            autocomplete="off"
            required
            {...register("new_password")}
          />
          <Label htmlFor="re_password">Ripeti nuova password</Label>
          <MobileFormInputStyle
            id="re_password"
            defaultValue={user.user_citta}
            autocomplete="off"
            {...register("re_password")}
            type={"password"}
            required
          />

          <div style={{ textAlign: "center", marginTop: 40 }}>
            <CustomButton>Salva</CustomButton>
          </div>
        </form>
      </div>
    </RightContainer>
  )
}
