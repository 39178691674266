import styled from "styled-components"
import { device } from "../../../constants/breakpoints"

export const Container = styled.div`
  background-color: #f6f6f6;
  padding: 50px;
  @media ${device.tablet} {
    padding: 0;
    padding-right: 25px;
    padding-top: 20px;
    padding-left: 25px;
    padding-bottom: 20px;
    background-color: white;
  }
`

export const PrefInnerItemHeart = styled.div`
  box-shadow: 0 3px 6px #d6d6d6;
  justify-content: center;
  text-align: center;
  height: 25px;
  width: 25px;
  border-radius: 12.5px;
  cursor: pointer;
  & :hover {
    box-shadow: 0 3px 12px #d6d6d6;
  }
`

export const Wrapper = styled.div`
  max-width: 1400px;
  margin: auto;
  justify-content: ${props => (props.center ? "center" : "flex-start")};
  @media (min-width: 1220px) {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }
`

export const Row = styled.div`
  display: inline-flex;
  flex-direction: row;
  justify-content: center;
`

export const PrefContainer = styled.div`
  flex-direction: column;
  background: white;
  width: ${props =>
    props.items > 15
      ? "100%"
      : props.items > 10
      ? "66%"
      : props.items > 5
      ? "48%"
      : "30%"};
  border-radius: 12px;
  margin: 10px;
  margin-bottom: 30px;
  box-shadow: 0 3px 6px #d6d6d6;
  @media (max-width: 1220px) {
    background: #e9eff4;
    width: 100%;
  }
  @media ${device.tablet} {
    margin: 0;
    margin-top: 10px;
  }
`

export const PrefInner = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 5px;
  padding-top: 10px;
  max-height: 250px;

  background: white;
  border-bottom-left-radius: 12px;
  border-bottom-right-radius: 12px;
  flex-wrap: wrap;
  @media ${device.tablet} {
    padding: ${({ open }) => (open ? "20px" : "0px")};
    padding-left: 5px;
    padding-right: 20px;
    flex-direction: row;
    max-height: ${({ open }) => (open ? "1000px" : "0px")};
    transition: all 0.5s ease-in-out;
    overflow: hidden;
  }
`

export const PrefInnerItem = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  min-width: 180px;
  margin-left: 20px;
  margin-right: 20px;
  border-bottom: 0.5px solid #f2f2f2;
  padding-bottom: 10px;
  padding-top: 10px;
  &:last-of-type {
    border-bottom: none;
  }
  @media ${device.tablet} {
    width: 100%;
    margin-right: 0;
  }
`

export const PrefInnerItemText = styled.p`
  font-size: 20;
  font-weight: 500;
  margin-bottom: 0;
  color: #172d4a !important;
  transition: color 0.5s ease;
  cursor: pointer;
  :hover {
    color: ${({ theme }) => theme.blue}!important;
  }
`

export const PrefInnerColumn = styled.div`
  display: flex;
  flex-direction: column;
`

export const PrefTitle = styled.h6`
  font-weight: 500 !important;
  font-size: 22px;
  color: #264a79;
  font-weight: 500;
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
  background-color: #e9eff4;
  padding-left: 25px;
  padding-top: 10px;
  padding-bottom: 5px;
  @media ${device.tablet} {
    border-radius: 12px;
    font-size: 18px;
    &::after {
      margin-top: 3px;
      float: right;
      margin-right: 25px;
      border-style: solid;
      border-width: 0.1em 0.1em 0 0;
      content: "";
      display: inline-block;
      height: 0.45em;
      left: 0.15em;
      position: relative;

      top: 0.15em;
      transform: ${({ open }) => (!open ? "rotate(135deg)" : "rotate(-45deg)")};
      vertical-align: top;
      width: 0.45em;
    }
  }
`
