import React, { useState } from "react"
import styled from "styled-components"
import { ProfiloItems } from "./utils/ProfiloItems"
import {
  logoutSuccess,
  selectCurrentUser,
  updateUserImageAsync,
} from "../../redux/userSlice"
import { useSelector, useDispatch } from "react-redux"
import { device } from "../../constants/breakpoints"
import {
  IconButton,
  useMediaQuery,
  useTheme,
  Dialog,
  Button,
  DialogTitle,
  DialogActions,
  DialogContent,
  DialogContentText,
} from "@material-ui/core"
import EditIcon from "@material-ui/icons/Edit"
import { openErrorToast } from "../../redux/toastSlice"
import baseImg from "../../images/add_photo_mobile.png"

const Container = styled.div`
  background: white;
  max-width: 300px;
  padding-left: 10%;
  padding-right: 10%;
  box-shadow: 0px 3px 6px #00000012;
  padding-bottom: 20px;
  padding-top: 20px;
  text-align: left;
  align-content: center;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  min-height: 620px;
  @media ${device.tablet} {
    max-width: 100%;
    text-align: center;
  }
`
const Item = styled.li`
  margin-top: 20px;
  cursor: pointer;
  margin-bottom: 20px;
  font-size: 15px;
  color: ${({ active, theme }) => (active ? theme.blue : theme.black)};
  &:hover {
    color: ${({ theme }) => theme.blue};
  }
`
const List = styled.ul`
  margin-top: 40px;
  padding-left: 0;
  list-style-type: none;
`
const UserName = styled.span`
  text-align: center;
  color: ${({ theme }) => theme.black};
  font-size: 18px;
`
const NickName = styled.span`
  text-align: center;
  color: ${({ theme }) => theme.gray};
  font-weight: bold;
  font-size: 12px;
`
const Profile = styled.div`
  text-align: center;
  position: relative;
`
const ProfileImage = styled.div`
  border-radius: 8px;
  width: 100%;
  max-width: 200px;
  height: 200px;
  margin: auto;
  margin-bottom: 10px;
  background: url(${props => props.img}) center center/cover no-repeat;
  object-fit: cover;
  justify-content: center;
`

const CloseIconWrapper = styled(IconButton)`
  box-shadow: 0px 3px 6px #00000012;
  background: white !important;
  position: absolute !important;
  padding: 5px !important;
  right: 5px;
  top: -5px;
`
const FakeInput = styled.input`
  background: red;
  position: relative;
`

const DialogAlert = ({ open, onConfirm, onCancel }) => {
  const theme = useTheme()
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"))

  return (
    <Dialog
      fullScreen={fullScreen}
      open={open}
      onClose={onCancel}
      aria-labelledby="responsive-dialog-title"
    >
      <DialogTitle id="responsive-dialog-title">
        {"Modifica immagine del profilo?"}
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          Sicuro di voler aggiornare l'immagine del profilo?
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button autoFocus onClick={onCancel} color="primary">
          No
        </Button>
        <Button onClick={onConfirm} color="primary" autoFocus>
          Si, aggiorna immagine profilo
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default function ProfiloInnerContainer({ setActive, active }) {
  const user = useSelector(selectCurrentUser)
  const [image, setImage] = useState(null)
  const [open, setOpen] = useState(false)
  const dispatch = useDispatch()

  const onConfirm = async () => {
    await dispatch(updateUserImageAsync(image, user))
    setOpen(false)
  }

  React.useEffect(() => {
    if (image?.length) {
      setOpen(true)
    }
  }, [image])

  function getBase64(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader()
      reader.readAsDataURL(file)
      reader.onload = () => resolve(reader.result)
      reader.onerror = error => reject(error)
    })
  }

  const handleChange = async file => {
    try {
      const base64 = await getBase64(file)
      setImage(base64)
    } catch (e) {
      dispatch(openErrorToast("Si è verificato un errore, riprova"))
    }
  }

  return (
    <Container>
      <DialogAlert
        open={open}
        onConfirm={onConfirm}
        onCancel={() => setOpen(false)}
      />
      <Profile>
        <CloseIconWrapper
          edge="start"
          color="inherit"
          onClick={() => null}
          aria-label="close"
        >
          <FakeInput
            onChange={e =>
              handleChange(e.target.files[e.target.files.length - 1])
            }
            id={`upload-profile`}
            accept="image/*"
            type={"file"}
            hidden
          />
          <label
            style={{
              width: 20,
              height: 20,
              display: "flex",
              alignContent: "center",
              alignItems: "center",
              justifyContent: "center",
            }}
            for={`upload-profile`}
          >
            <EditIcon style={{ fontSize: 16 }} />
          </label>
        </CloseIconWrapper>
        <ProfileImage
          img={user.user_image || require("../../images/placeholder.png")}
        />

        <UserName>{user.user_name}</UserName>
        <br />
        <NickName>@{user.user_nickname}</NickName>
      </Profile>
      <List>
        {ProfiloItems.map((p, index) => (
          <Item
            onClick={() => {
              if (p.text === "Logout") {
                return dispatch(logoutSuccess())
              }
              setActive(p.text)
            }}
            key={p.key}
            active={active === p.text}
          >
            {p.text}
          </Item>
        ))}
      </List>
    </Container>
  )
}
