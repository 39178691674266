import React, { useRef, useState } from "react"
import { selectUserRichieste } from "../../../redux/userSlice"
import { useSelector } from "react-redux"
import styled from "styled-components"
import {
  CardsImage,
  EmptyText,
  Medium,
  NoItemsContainer,
  RightContainer,
} from "../utils/ProfiloStyles"
import { colors } from "../../../constants/Colors"
import moment from "moment"
import useWindowDimensions from "../../../hooks/useWindowDimensions"
import { breakpoints } from "../../../constants/breakpoints"
import ReusableModal from "../../ReusableModal/ReusableModal"
import ReusableDialog from "../../ReUsableDialog/ReusableDialog"
import ProfiloRichiesta from "./ProfiloRichiesta"
const Richiesta = styled.div`
  margin-bottom: 20px;
  padding-bottom: 10px;
  border-bottom: 0.1px solid #f2f2f2;
  &:last-of-type {
    border-bottom: none;
  }
`

export default function ProfiloRichiesteContainer() {
  const richieste = useSelector(selectUserRichieste)
  //const richieste = MockedRichiestex
  const parentRef = useRef()
  const { width } = useWindowDimensions()
  const [open, setOpen] = useState(false)
  const [index, setIndex] = useState(0)

  const openRichiestaModal = i => {
    setIndex(i)
    setOpen(true)
  }

  if (!richieste?.length > 0) {
    return (
      <RightContainer style={{ display: "flex", flexDirection: "column" }}>
        <NoItemsContainer />
        <EmptyText style={{ marginTop: 15 }}>
          Non hai inviato nessuna richiesta
        </EmptyText>
      </RightContainer>
    )
  }
  return (
    <RightContainer ref={parentRef}>
      {width > breakpoints.tablet ? (
        <ReusableModal
          open={open}
          close={() => setOpen(false)}
          title={"Richiesta"}
          parentRef={parentRef}
          content={
            <ProfiloRichiesta
              chirurgoNome={richieste[index].chirurgo}
              richiesta={richieste[index]}
            />
          }
        />
      ) : (
        <ReusableDialog
          open={open}
          close={() => setOpen(false)}
          title={"Richiesta"}
          parentRef={parentRef}
          content={
            <ProfiloRichiesta
              chirurgoNome={richieste[index].chirurgo}
              richiesta={richieste[index]}
            />
          }
        />
      )}
      {richieste.map((richiesta, index) => (
        <Richiesta style={styles.container} key={richiesta.id_sys}>
          <img style={styles.image} src={richiesta.ChirurgoImage} />
          <div style={styles.column}>
            <Medium>{richiesta.chirurgo}</Medium>
            <br />
            <Medium style={styles.trattamento}>{richiesta.trattamento}</Medium>
            <br />
            <Medium style={styles.motivazione}>{richiesta.motivazione}</Medium>
            <br />
            <Medium style={styles.data}>
              {moment(richiesta.data).format("DD.MM.YYYY")}
            </Medium>
            <div
              style={{ cursor: "pointer" }}
              onClick={() => openRichiestaModal(index)}
            >
              <span style={{ color: colors.BLUE, fontSize: 12 }}>Leggi</span>
            </div>
          </div>
        </Richiesta>
      ))}
    </RightContainer>
  )
}

const styles = {
  container: {
    display: "flex",
    marginHorizontal: 40,
    marginVertical: 5,
    flexDirection: "row",
  },
  chirurgo: {
    fontSize: 16,
    color: colors.BLACK,
  },
  trattamento: {
    marginTop: 2.5,
    fontSize: 13,
    color: colors.GRAY_01,
    textTransform: "uppercase",
  },
  motivazione: {
    marginTop: 2.5,
    color: colors.LIGHT_BLUE,
    fontSize: 13,
  },
  data: {
    marginTop: 2.5,
    color: colors.GRAY_01,
    fontSize: 13,
  },
  image: {
    ...CardsImage,
    marginTop: 5,
    marginBottom: "auto",
  },
  column: {
    flexDirection: "column",
  },
}
