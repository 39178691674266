import React, { useEffect, useState } from "react"
import { Bold, CardsImage, Medium, Regular } from "../utils/ProfiloStyles"
import moment from "moment"
import { colors } from "../../../constants/Colors"
import { GET_RISPOSTE } from "../../../constants/api"
import axios from "axios"

const RichiestaBubble = ({ richiesta }) => (
  <div style={styles.bubble}>
    <Bold style={styles.messaggio}>{richiesta.contatto}</Bold>
    <br />
    <Medium style={styles.data}>
      {moment(richiesta.data).format("DD.MM.YYYY")}
    </Medium>
    <br />
    <Regular style={styles.messaggio}>{richiesta.messaggio}</Regular>
  </div>
)

const RispostaBubble = ({ risposta, chirurgoNome }) => (
  <div style={styles.bubbleRight}>
    <Bold style={styles.messaggio}>{chirurgoNome}</Bold>
    <br />
    <Medium style={styles.data}>
      {moment(risposta.data_creazione).format("DD.MM.YYYY")}
    </Medium>
    <br />
    <Regular style={styles.messaggio}>{risposta.risposta}</Regular>
  </div>
)

export default function ProfiloRichiesta({ richiesta, chirurgoNome }) {
  const [risposte, setRisposte] = useState([])

  const fetchRisposte = async () => {
    const { data } = await axios.get(GET_RISPOSTE(richiesta.id_sys))
    setRisposte(data)
  }

  useEffect(() => {
    fetchRisposte()
  }, [])

  return (
    <div style={styles.container}>
      <div style={styles.topContainer}>
        <img style={styles.image} src={richiesta.ChirurgoImage} />
        <div style={styles.column}>
          <Medium style={styles.chirurgo}>{richiesta.chirurgo}</Medium>
          <Medium style={styles.trattamento}>{richiesta.trattamento}</Medium>
          <Medium style={styles.motivazione}>{richiesta.motivazione}</Medium>
        </div>
      </div>
      <div style={styles.body}>
        <RichiestaBubble richiesta={richiesta} />
        {risposte.length > 0 &&
          risposte.map(r => (
            <RispostaBubble
              risposta={r}
              chirurgoNome={chirurgoNome}
              key={r.id_sys}
            />
          ))}
      </div>
    </div>
  )
}

const styles = {
  container: {
    padding: 20,
    width: "100%",
  },
  messaggio: {
    fontSize: 14,
    marginTop: 5,
    color: colors.BLACK,
  },
  body: { marginTop: 10 },
  column: { display: "flex", flexDirection: "column", textAlign: "left" },
  topContainer: {
    display: "flex",
    flexDirection: "row",
  },
  chirurgo: {
    fontSize: 16,
    color: colors.BLACK,
  },
  trattamento: {
    marginTop: 12,
    fontSize: 13,
    color: colors.GRAY_01,
    textTransform: "uppercase",
  },
  motivazione: {
    marginTop: 12,
    color: colors.LIGHT_BLUE,
    fontSize: 13,
  },
  data: {
    marginTop: 5,
    color: colors.GRAY_01,
    fontSize: 13,
  },
  image: {
    ...CardsImage,
  },
  bubble: {
    textAlign: "left",
    backgroundColor: colors.LIGHT_OCEAN,
    padding: 10,
    borderRadius: 6,
  },
  bubbleRight: {
    textAlign: "right",
    backgroundColor: colors.LIGHT_GRAY_03,
    marginTop: 10,
    marginLeft: 10,
    padding: 10,
    borderRadius: 6,
  },
}
