import React, { useEffect, useState } from "react"
import { useSelector } from "react-redux"
import { selectCurrentUser } from "../../redux/userSlice"
import ProfiloInnerContainer from "./ProfiloInnerContainer"
import styled from "styled-components"
import { navigate } from "gatsby"
import { Col, Container as BottContainer, Row as RowB } from "react-bootstrap"
import ReusableAppBar from "../Atoms/ReusableAppBar"
import useWindowDimensions from "../../hooks/useWindowDimensions"
import { breakpoints } from "../../constants/breakpoints"
import ProfiloNotificheContainer from "./ProfiloNotificheContainer/ProfiloNotificheContainer"
import ProfiloRichiesteContainer from "./ProfiloRichiesteContainer/ProfiloRichiesteContainer"
import ProfiloRecensioniContainer from "./ProfiloRecensioniContainer/ProfiloRecensioniContainer"
import ProfiloDatiUtenteContainer from "./ProfiloDatiUtenteContainer/ProfiloDatiUtenteContainer"
import ProfiloPreferenzeContainer from "./ProfiloPreferenzeContainer/ProfiloPreferenzeContainer"
import ProfiloCambiaPasswordContainer from "./ProfiloCambiaPasswordContainer/ProfiloCambiaPasswordContainer"
const Row = styled(RowB)`
  --bs-gutter-x: 0;
`

const Container = styled(BottContainer)`
  padding-top: 40px;
  padding-bottom: 180px;
  background: #fafafa !important;
  padding-left: 5%;
  padding-right: 5%;
`
export default function ProfiloContainer() {
  const user = useSelector(selectCurrentUser)
  const { width } = useWindowDimensions()
  const isMobile = width < breakpoints.tablet
  const [active, setActive] = useState(null)

  useEffect(() => {
    if (!user?.user_email) {
      navigate("/")
    }
  }, [user])

  useEffect(() => {
    if (!isMobile) {
      setActive("Dati personali")
    } else {
      setActive(null)
    }
  }, [isMobile])

  if (!user?.user_email) {
    return null
  }
  return (
    <Container fluid>
      {isMobile && (
        <ReusableAppBar
          title={active || "Profilo"}
          onBackPress={active ? () => setActive(null) : null}
        />
      )}
      <RowB>
        {isMobile && active ? null : (
          <Col sm={12} md={4} lg={3}>
            <ProfiloInnerContainer setActive={setActive} active={active} />
          </Col>
        )}
        <Col sm={12} md={8} lg={9}>
          {active === "Dati personali" && <ProfiloDatiUtenteContainer />}
          {active === "Notifiche" && <ProfiloNotificheContainer />}
          {active === "Richieste" && <ProfiloRichiesteContainer />}
          {active === "Trattamenti Preferiti" && <ProfiloPreferenzeContainer />}
          {active === "Recensioni" && <ProfiloRecensioniContainer />}
          {active === "Cambia Password" && <ProfiloCambiaPasswordContainer />}
        </Col>
      </RowB>
    </Container>
  )
}
