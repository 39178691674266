export const ProfiloItems = [
  {
    key: "item2",
    text: "Dati personali",
  },
  {
    key: "item1",
    text: "Notifiche",
  },
  {
    key: "item3",
    text: "Richieste",
  },
  {
    key: "item4",
    text: "Trattamenti Preferiti",
  },
  {
    key: "item6",
    text: "Recensioni",
  },
  {
    key: "item7",
    text: "Cambia Password",
  },
  {
    key: "item9",
    text: "Logout",
  },
]

export const SettingsItems = ({ navigation }) => [
  {
    key: "item-1",
    text: "Notifiche Push",
  },
  {
    key: "item-2",
    text: "Cambio Password",
  },
  {
    key: "item-3",
    text: "Privacy",
  },
]

export const OthersItems = ({ navigation }) => [
  {
    key: "item-1",
    text: "Chi Siamo",
  },
  {
    key: "item-2",
    text: "FAQS",
  },
  {
    key: "item-3",
    text: "Vota L'app",
  },
]
