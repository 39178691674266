import React, { createContext, useContext, useState } from "react"
import {
  Container,
  Wrapper,
  Row,
  PrefContainer,
  PrefContainerSm,
  PrefTitle,
  PrefInner,
  PrefInnerItem,
  PrefInnerItemText,
  PrefInnerColumn,
  PrefInnerItemHeart,
} from "./styles/Preferences.styled.js"
import { faHeart } from "@fortawesome/free-solid-svg-icons"
import { faHeart as emptyHeart } from "@fortawesome/free-regular-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import Snackbar from "@material-ui/core/Snackbar"
import MuiAlert from "@material-ui/lab/Alert"
import {
  addPreferenzaAsync,
  preferenzaLoading,
  rimuoviPreferenzaAsync,
  selectCurrentUser,
  selectPreferenzaLoading,
  selectUserPref,
} from "../../redux/userSlice"
import { useSelector, useDispatch } from "react-redux"
import { openErrorToast, openInfoToast } from "../../redux/toastSlice"
import { CircularProgress } from "@material-ui/core"
const OpenCtx = createContext()

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />
}

const Preferences = ({ children, ...restProps }) => {
  const [open, setOpen] = useState(false)
  return (
    <OpenCtx.Provider
      value={{
        open,
        setOpen,
      }}
    >
      <Container {...restProps}>{children}</Container>
    </OpenCtx.Provider>
  )
}

Preferences.Wrapper = function PrefWrapper({ children, ...restProps }) {
  return <Wrapper {...restProps}>{children}</Wrapper>
}

Preferences.Row = function PrefRow({ children, ...restProps }) {
  return <Row {...restProps}>{children}</Row>
}

Preferences.PrefContainer = function PrefPrefContainer({
  children,
  ...restProps
}) {
  return <PrefContainer {...restProps}>{children}</PrefContainer>
}

Preferences.PrefInner = function PrefPrefInner({
  children,
  index,
  ...restProps
}) {
  const { open } = useContext(OpenCtx)
  return (
    <PrefInner open={open == index} {...restProps}>
      {children}
    </PrefInner>
  )
}

Preferences.PrefInnerItem = function PrefPrefInnerItem({
  children,
  ...restProps
}) {
  return <PrefInnerItem {...restProps}>{children}</PrefInnerItem>
}

Preferences.PrefInnerItemHeart = function PrefPrefInnerItemHeart({ pref }) {
  const user = useSelector(selectCurrentUser)
  const preferences = useSelector(selectUserPref)
  const dispatch = useDispatch()
  const loading = useSelector(selectPreferenzaLoading)
  const isActive = user?.user_email
    ? preferences.find(p => p.id_tra === pref.id_sys)
    : false

  const handleClick = () => {
    if (!user?.user_email) {
      return dispatch(
        openErrorToast("Devi eseguire il login per aggiungere una preferenza!")
      )
    }
    if (!isActive) {
      dispatch(
        addPreferenzaAsync(
          {
            id_contatto: user.user_id,
            id_trattamento: pref.id_sys,
            trattamento: pref.trattamento,
          },
          () => dispatch(openInfoToast("Trattamento aggiunto ai preferiti")),
          () => dispatch(openErrorToast("Oops si è verificato un errore"))
        )
      )
    } else {
      dispatch(
        rimuoviPreferenzaAsync(
          {
            id_utente: user.user_id,
            id_trattamento: pref.id_sys,
          },
          () => dispatch(openInfoToast("Trattamento rimosso dai preferiti")),
          () => dispatch(openErrorToast("Oops si è verificato un errore"))
        )
      )
    }
  }

  return (
    <PrefInnerItemHeart onClick={() => handleClick()}>
      {loading === pref.id_sys ? (
        <CircularProgress size={11} color={"#337ab7"} />
      ) : isActive ? (
        <FontAwesomeIcon
          style={{ color: "#337ab7", fontSize: 14 }}
          icon={faHeart}
        />
      ) : (
        <FontAwesomeIcon
          style={{ color: "#337ab7", fontSize: 14 }}
          icon={emptyHeart}
        />
      )}
    </PrefInnerItemHeart>
  )
}

Preferences.PrefInnerItemText = function PrefPrefInnerItemText({
  children,
  ...restProps
}) {
  return <PrefInnerItemText {...restProps}>{children}</PrefInnerItemText>
}

Preferences.PrefInnerColumn = function PrefPrefInnerColumn({
  children,
  ...restProps
}) {
  return <PrefInnerColumn {...restProps}>{children}</PrefInnerColumn>
}

Preferences.PrefTitle = function PrefPrefTitle({
  children,
  index,
  ...restProps
}) {
  const { setOpen, open } = useContext(OpenCtx)
  return (
    <PrefTitle
      open={open == index}
      onClick={() => {
        open == index ? setOpen(-1) : setOpen(index)
      }}
      {...restProps}
    >
      {children}
    </PrefTitle>
  )
}

export default Preferences
