import styled from "styled-components"
import { device } from "../../../constants/breakpoints"
import img from "../../../images/no-items.png"

export const CardsImage = {
  height: 100,
  margin: 20,
  marginTop: 0,
  marginLeft: 0,
  borderRadius: 6,
  objectFit: "cover",
  width: 100,
}

export const NoItemsContainer = styled.div`
  background-image: url(${img});
  justify-content: center;
  background-repeat: no-repeat;
  height: 200px;
  width: 200px;
`

export const RightContainer = styled.div`
  background: white;
  min-height: 620px;
  padding-left: 5%;
  padding-right: 5%;
  text-align: left;
  box-shadow: 0px 3px 6px #00000012;
  padding-bottom: 20px;
  padding-top: 20px;
  text-align: left;
  align-content: center;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  @media ${device.tablet} {
    max-width: 100%;
    text-align: center;
  }
`

export const Medium = styled.span`
  font-family: Roboto;
`

export const Bold = styled.span`
  font-family: Roboto;
  font-weight: bold;
`
export const Regular = styled.span`
  font-family: Roboto;
  font-weight: 400;
`
export const EmptyText = styled(Medium)`
  text-align: center;
`
