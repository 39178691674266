import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"

import "../constants/global.css"

import { useBreadcrumb } from "gatsby-plugin-breadcrumb"
import ProfiloContainer from "../components/ProfiloContainer/ProfiloContainer"

const ProfiloPage = ({ location }) => {
  const { crumbs } = useBreadcrumb({
    location,
    crumbLabel: "Profilo",
    crumbSeparator: " / ",
  })

  return (
    <Layout>
      <SEO title="Profilo utente" />
      <ProfiloContainer />
    </Layout>
  )
}

export default ProfiloPage
