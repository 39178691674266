import { Modal, Slide } from "@material-ui/core"
import React, { useState } from "react"
import CloseIconContainer from "../Atoms/CloseIcon"

import {
  useStyles,
  Wrapper,
  Container,
  Inner,
  HeaderTitle,
  Header,
} from "../AuthModal/AuthModal.styled"

export default function ReusableModal({
  parentRef,
  open,
  close,
  title,
  content,
}) {
  const classes = useStyles()
  return (
    <Modal
      disablePortal
      disableEnforceFocus
      disableAutoFocus
      open={open}
      onClose={close}
      aria-labelledby="server-modal-title"
      aria-describedby="server-modal-description"
      className={classes.modal}
      container={() => parentRef.current}
    >
      <Slide direction="up" in={open} mountOnEnter unmountOnExit>
        <Wrapper>
          <Container>
            <Header>
              <CloseIconContainer
                style={{ marginRight: "auto", visibility: "hidden" }}
              />
              <HeaderTitle>{title}</HeaderTitle>
              <CloseIconContainer
                onClick={close}
                style={{ marginLeft: "auto", color: "white", marginTop: -5 }}
              />
            </Header>
            <Inner>{content}</Inner>
          </Container>
        </Wrapper>
      </Slide>
    </Modal>
  )
}
