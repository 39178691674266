import React, { useEffect } from "react"
import { makeStyles } from "@material-ui/core/styles"

import Dialog from "@material-ui/core/Dialog"
import AppBar from "@material-ui/core/AppBar"
import Toolbar from "@material-ui/core/Toolbar"
import IconButton from "@material-ui/core/IconButton"
import Typography from "@material-ui/core/Typography"
import CloseIcon from "@material-ui/icons/Close"
import Slide from "@material-ui/core/Slide"
import { colors } from "../../constants/Colors"

import styled from "styled-components"

const useStyles = makeStyles(theme => ({
  appBar: {
    position: "relative",
    background: colors.BLUE,
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
}))

const Inner = styled.div`
  margin: 30px;
  padding-bottom: 50px;
`

const P = styled.p`
  margin-top: 25px;
  margin-bottom: 5px;
  margin-left: 5px;
  font-weight: 500;
`
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />
})

export default function ReusableDialog({ open, close, title, content }) {
  const classes = useStyles()

  return (
    <Dialog
      fullScreen
      open={open}
      onClose={close}
      TransitionComponent={Transition}
    >
      <AppBar className={classes.appBar}>
        <Toolbar>
          <Typography variant="h6" className={classes.title}>
            {title}
          </Typography>
          <IconButton
            edge="start"
            color="inherit"
            onClick={close}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
        </Toolbar>
      </AppBar>
      <Inner>{content}</Inner>
    </Dialog>
  )
}
